.plugin__product {
    .product__item {

        @media (--ipad) {
            display: flex;
            flex-shrink: 0;
        }

        .item__image {
            flex-shrink: 0;
            margin-bottom: 20px;
            @media (--ipad) {
                margin-right: 30px;
                margin-bottom: 0;
            }
            img {
                width: 100%;
                min-width: 240px;
            }
        }
        .item__description {
            .description__header {
                display: flex;
                margin-bottom: 15px;
            }
            .header__title {
                width: 100%;
                font-size: 16px;
                font-weight: 600;
                a {
                    linkColors: $black, $primary;
                }
                .title__dimensions {
                    display: block;
                    color: $text-grey;
                    font-size: 11px;
                    i {
                        color: $primary;
                        font-size: 18px;
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
            }
            .header__price {
                width: 100%;
                text-align: right;
            }
            .price__before {
                color: #aaaaaa;
                font-size: 12px;
                text-decoration: line-through;
            }
            .price__now {
                color: $primary;
                font-size: 18px;
            }

            .description__copy {
                margin-bottom: 40px;
                p {
                    font-size: 12px;
                    line-height: 22px;
                    color: $text-grey;
                    max-width: 670px;
                }
            }
            .description__actions {
                .btn {
                    margin-bottom: 15px;
                    width: 100%;
                }
                @media(--upMobile) {
                    display: flex;
                }
                @media(--ipad) {
                    .btn {
                        width: auto;
                    }
                }
                .actions__more {
                    margin-right: 20px;
                }
            }
        }
    }
}