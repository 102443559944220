.plugin__product_variants {
    margin-top: 40px;
    margin-bottom: 60px;

    .variants__delivery {

    }
    .variants__title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 20px;
        i {
            color: $primary;
            font-size: 18px;
            display: inline-block;
            margin-right: 5px;
        }
    }
    .btn {
        margin-top: 20px;
    }
    .variants__delivery {
        .delivery__status {
            margin-bottom: 15px;
            color: #222222;
            font-weight: 600;
            font-size: 12px;
            color: green;
            margin-bottom: 45px;
        }
        .delivery__headline {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
            i {
                color: $primary;
                font-size: 18px;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
    .variants__hint {
        font-size: 10px;
        text-transform: none;
        font-weight: 400;
        @media(--onlyMobile) {
            display: block;
            padding-left: 28px;
        }
        a {
            linkColors: $black, $primary;
            text-decoration: underline;
        }
    }
    .variants__item {
        .item__dimensions {
            display: inline-block;
            &:after {
                display: inline-block;
                padding-right: 3px;
                padding-left: 3px;
                content: " | ";
                font-size: 12px;
                font-weight: 400;
                color: #e6e7e8;
            };

        }
        .item__price {
            font-weight: 600;
            color: #404040;
        }
        .item__price--special {
            .price__now {
                color: red;
                font-size: 14px;
            }
        }
/*        .item__dimensions {
            @media(--untilIpad) {
                display: block!important;
            }
        }*/

        .price__before {
            font-size: 12px;
            font-weight: 400;
            text-decoration: line-through;
        }

        .item__qyt {
            font-weight: 400;
            color: #838383;
        }
        .item__special {
            background-color: red;
            color: #FFF;
            border-radius: 15px;
            padding: 4px 8px;
            font-size: 10px;
            display: inline-block;
            margin-left: 10px;
            line-height: 16px;
        }
    }

}