
@font-face {
  font-family: 'SinkinSans';
  font-path: '../fonts/SinkinSansRegular';
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SinkinSans';
  font-path: '../fonts/SinkinSansMedium';
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SinkinSans';
  font-path: '../fonts/SinkinSansSemiBold';
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SinkinSans';
  font-path: '../fonts/SinkinSansBold';
  font-weight: 700;
  font-style: normal;
}