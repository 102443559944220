.plugin__news {
    .news__entry {
        padding-right: 20px;
        margin-bottom: 20px;
        clear: both;
        .entry__image {
            margin-right: 30px;
            float: left;
            img {
                max-width: 120px;
            }
        }
        .entry__title {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        .entry__copy {
            font-size: 12px;
            line-height: 22px;
            color: $text-grey;
        }
    }
}