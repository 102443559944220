html,body {
    overflow-x: hidden;
}

#header {
    border-bottom: 1px solid $border-grey;
    .container--centered {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media (--atDesktop) {
            padding-top: 23px;
            padding-bottom: 23px;
        }
    }

    .header__logo {
        .logo--mobile {
            position: relative;
            top: 3px;
        }

        .logo--desktop {
            @media (--onlyMobile) {
                display: none;
            }
            @media (--atDesktop) {
                display: block;
            }
        }

        .logo--mobile {
            @media (--upMobile) {
                display: none;
            }
        }
    }


    .header__nav {
        display: none;
        @media (--atDesktop) {
            display: block;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            li a {
                text-decoration: none;
                padding: 0 7px;
                linkColors: $black, $primary;
                font-size: 11px;
                text-transform: uppercase;
            }
        }
    }

    .header__meta {
        font-size: 12px;
        display: flex;

        .meta__phone {
            font-size: 13px;
            color: $grey;
            padding-right: 20px;
            i {
                padding-right: 5px;
                font-size: 18px;
            }
            display: none;
            @media (--atWidescreen) {
                display: flex;
                align-items: center;
            }
        }
        .meta__cart {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 13px;
            position: relative;
            top: -2px;
            a {
                linkColors: $primary;
            }
            i {
                padding-right: 5px;
                font-size: 18px;
            }

            .cart__description {
                @media (--desktopBreak) {
                    display: none;
                }
            }
        }
    }
}

