.plugin__image {
    width: 100%;
    margin-bottom: 16px;
    img {
        width: 100%;
    }
    .image__display {
        width: 100%;
    }
}

.plugin_link {
    &.plugin_link--download {
        position: relative;
        left: 20px;
        margin-right: 20px;
        .icon-download {
            position: absolute;
            left: -20px;
        }
    }
}