
input:focus,
textarea:focus {
    border: none;
    outline: none;
    outline:0;
}

textarea {
    height: auto;
    resize: vertical;
    line-height: 24px;
}
input,
textarea {
    appearance: none;
    border: none;
    text-shadow: none;
    box-shadow: none;
}

textarea {
    border: 1px solid $input-normal-border;
}


form {
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }

    .p5050 {
        @media(--ipad) {
            display: flex;
            justify-content: space-between;
            > div {
                width: 49%;
            }
        }
    }
    label {
        display: block;
        font-size: 12px;
        line-height: 21px;
        color: $text-grey;
        padding-left: 5px;
        margin-bottom: 3px;
    }
    .group__item {
        margin-bottom: 5px;
    }
    .group__item--error  {
        label {
            color: red;
        }
        input[type="text"],
        input[type="password"],
        textarea {
            border: 1px solid red;
        }
    }

    .item__input {
        margin-bottom: 12px;
    }

    input[type="text"],
    input[type="password"],
    textarea {
        @include font-smoothing();
        display: inline-block;
        vertical-align: middle;
        height: 40px;

        padding: 0px 10px;
        line-height: 1!important;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        font-size: 14px;

        background-color: transparent;
        border: 1px solid $input-normal-border;
        border-radius: 3px;
        width: 100%;

        transition: border linear .2s, box-shadow linear .2s;

        &:focus {
            outline: none;
            border: 1px solid $input-focus-border;
        }

        &:disabled {
            user-select: none;
            cursor: not-allowed;
            background: $input-normal-border;
        }
    }
    .input__error {
        font-size: 11px;
        color: red;
    }

    textarea {
        height: auto;
        min-height: 90px;
        padding: 10px;
    }
    .group__item {
        position: relative;
    }

    .item__suggestions {
        margin: 0;
        padding: 0;
        position: absolute;
        background: white;
        width: 100%;
        z-index: 1;
        margin-top: -12px;
        border: 1px solid $input-normal-border;
        border-top: 1px solid $input-normal-border;
        list-style: none;
        padding: 0;
        li {
            width: 100%;
            padding: 0px !important;
            &:before {
                content: "";
            }
            a {
                display: block;
                padding: 8px 10px 6px;
                text-decoration: none;
                border-bottom: 1px solid $input-normal-border;
                linkColors: $black, $primary;
                &:hover {
                    background: $table-header-background;
                    color: black;
                }
            }
        }
        li:last-child a {
            border: none;
        }
        &.item__suggestions--error {
            border-color: $input-warning !important;
            border-top: 1px solid $input-normal-border !important;
        }
        &.item__suggestions--focus {
            border-color: $input-normal-border;
            border-top: 1px solid $input-normal-border;
        }
    }
    .item__input--radio {
        cursor: pointer;
        position: relative;
        padding-left: 35px;
        user-select: none;
        input[type=radio] {
            display: none;
        }
        label {
            @media(--upIpad) {
                line-height: 44px;
            }
        }
        input[type=radio] + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 11px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #cdcfd0;
            display: inline-block;
            margin-right: 10px;
        }
        input[type=radio]:checked + label:after {
            position: absolute;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            left: 5px;
            top: 16px;
            border-radius: 50%;
            background: #FFF;
            border: 1px solid #72c05a;
        }
        input[type=radio]:checked + label:before {
            background: $primary;
            border-color: #72c05a;
        }


    }
    .item__input--checkbox {

        input[type=checkbox] {
            display: none;
        }

        cursor: pointer;
        position: relative;
        padding-left: 35px;
        user-select: none;
        line-height: 24px;
        color: black;
        display: inline-block;
        @extend .icon-cross_16px_14px;

        &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0px;
            top: 2px;
            background-color: $checkbox-bg-color;
            border: 1px solid $checkbox-border-color;
        }

        &:before {
            font-family: "mlsystems-icons";
            font-size: 16px;
            position: absolute;
            z-index: 2;
            left: 3px;
            top: 1px;
            transform: scale(0);
        }

        &.item__input--checkbox-checked {
           &:after {
                background-color: $checkbox-bg-color;
                border: 1px solid $checkbox-border-color;
            }
            &:before {
                transform: scale(1);
                transition: transform 0.1s 0s cubic-bezier(0.455,0.03,0.215,1.33);
            }
        }

        .input__error {
            left: -3px;
            line-height: initial;
        }
    }
}

