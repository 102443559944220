.plugin__columns {
    .container--centered {
        display: block;
    }

    @media (--atDesktop) {
        .container--centered {
            display: flex;
        }
    }

    p {
        max-width: 700px;
    }

    &.plugin__columns--2 p {
        /*max-width: 100%;*/
    }
}
