.plugin__product_details {

    &.plugin__product_details--small {
        @media(--upMobile) {
            min-width: 100px;
        }
        @media(--upIpad) {
            padding-left: 20px;
            min-width: 200px;
        }
        @media(--atDesktop) {
            padding-left: 20px;
            max-width: 500px;
        }
        .details__headline {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            text-transform: uppercase;
            color: $darkgrey;
            margin-bottom: 22px;
            &:after {
                content: '';
                flex: 1;
                border-bottom: 2px dotted $lightgrey;
                margin: auto 15px;
            }
            &:before {
                margin-left: 0;
            }
        }
        .details__item {
            font-size: 11px;
            margin-bottom: 42px;
        }
        .copy__headline {
            color: $black;
            font-weight: 600;
        }
        .item__copy {
            padding-left: 90px;
            @media(--onlyMobile) {
                padding-left: 70px;

            }
        }
        .copy__description {
            color: $text-grey;
            p {
                font-size: 11px;
                line-height: 22px;
                color: $text-grey;
                max-width: 100%;
            }

        }
        .item__image {
            margin-bottom: 42px;
            position: absolute;
            @media(--atDesktop) {
                margin-bottom: 0px;
            }
            @media(--onlyMobile) {
                position: absolute;
                margin-right: 15px;
                width: 50px;
                height: 50px;
            }
            border-radius: 50%;
            width: 80px;
            height: 80px;
            margin-right: 35px;
            display: block;
            background-size: cover;
        }
    }
    &.plugin__product_details--normal {
        .details__headline {
            display: flex;
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
            text-transform: uppercase;
            color: $darkgrey;
            margin-bottom: 62px;
            &:before, &:after {
                content: '';
                flex: 1;
                border-bottom: 2px dotted $lightgrey;
                margin: auto 15px;
            }
            &:before {
                margin-left: 0;
            }
            &:after {
                margin-right: 0;
            }
        }
        .details__items {
            @media(--upIpad) {
                display: flex;
                justify-content: space-between;
            }
        }
        .details__item {
            text-align: center;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        .item__image {
            margin: 0 auto;
            margin-bottom: 20px;
            border-radius: 50%;
            width: 180px;
            height: 180px;
            display: block;
            background-size: cover;
        }
        .copy__headline {
            color: $black;
            font-weight: 600;
        }
        .item__copy {

        }
    }

}