.template__home {
    padding-top: 0;
    .plugin__teaser {

        .teaser__item {
            width: 100%;
            @media (--ipad) {
                width: 50%;
            }
        }
    }
    .plugin__product {
        .product__item {
            @media (--ipad) {
                justify-content: center;
            }
        }
    }
}
