footer {
    background: $footer-background;
    padding-top: 40px;

    .footer__navigation {
        color: #fefefe;
        display: flex;
        justify-content: space-between;
        @media (--untilIpad) {
            flex-wrap: wrap;
        }
        > div {
            margin-bottom: 50px;
        }
        .navigation__menu {

            color: #fefefe;
            font-size: 12px;
            a {
                linkColors: #FFF;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                ul {
                    padding-left: 20px;
                    a {
                        linkColors: $text-grey;
                    }
                }
                li {
                    line-height: 30px;
                }
            }
        }
        .navigation__address {
            .address__name {
                font-size: 18px;
                font-weight: 700;
                color: #a1c875;
                line-height: 30px;
            }
            .address__subtitle {
                font-size: 11px;
                margin-bottom: 7px;
                color: #FFF;
            }
            .address__street {
                font-size: 11px;
                line-height: 30px;
                color: #838383;
            }
            .address__phone, .address__fax, .address__mobile, .address__email {
                font-size: 12px;
                line-height: 30px;
            }
            .address__email a {
                linkColors: #FFF;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .footer__bottom {
        background: $footer-background-bottom;
        color: #61615e;
        font-size: 11px;
        padding: 15px 0;
        text-align: center;
        a {
            linkColors: #61615e, #FFF;
            text-decoration: underline;
        }
    }
}



#cookieConsentModal {
    .btn {
        white-space: normal;
        line-height: 18px;
    }
    a {
        color: rgb(113, 166, 97);
    }
    .btn-accept-all {
        background: rgb(113, 166, 97);
    }

}
