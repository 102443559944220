.plugin__heroslider {
    position: relative;
    .heroslider__frame {
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;
    }
    .frame__slides {
        display: inline-block!important;
        margin: 0!important;
        padding: 0!important;
    }
    li {
        position: relative;
        display: inline-block;
        padding: 0!important;
        margin: 0!important;
        &:before {
            content: ""!important;
        }
    }
    .slides__item {
        width: 100vw;
        @media (--ipad) {
            width: 34%;
        }
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    .heroslider__prev, .heroslider__next {
        position: absolute;
        z-index: 100;
        font-size: 64px;
        color: #FFF;
        top: 30%;
    }
    .heroslider__next {
        right: 30px;
    }
    .heroslider__prev {
        left: 30px;
    }
    &.plugin__heroslider--static {
        .heroslider__prev, .heroslider__next {
            display: none!important;
        }
        .item__image img {
            display: none;
        }
        .item__image {
            width: 100vw;
            height: 450px;
        }

    }
}