.checkout {
    h1 {
        margin-bottom: 38px;
        font-size: 14px;
        text-transform: uppercase;
    }

    margin-bottom: 150px;
    margin-top: 30px;
    .page__content {
        max-width: 860px;
    }

    .checkout__top {
        @media(--atDesktop) {
            display: flex;
            .checkout__cart, .preview__address {
                width: 75%;
            }
            .checkout__totals {
                width: 320px;
                padding-left: 50px;
                max-width: 320px;
            }
        }
    }

    .checkout__cart {

        @media(--atDesktop) {
            margin-bottom: 50px;
        }
        table {
            border: none;
            width: 100%;
            @media(--atDesktop) {
                margin-bottom: 30px;
            }
            border-bottom: 1px solid #e6e6e6;
            thead {
                background-color: $table-header-background;
                border-top: 1px solid $border-grey;
                border-bottom: 1px solid $border-grey;
                text-align: left;
                color: $text-grey;
                @media(--onlyMobile) {
                    display: none;
                }
                th {
                    text-align: left;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 15px 0;
                }
            }
            tbody {
                .item__image {
                    width: 80px;
                }
                .cart__item td {
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
                tr {
                    border-top: 1px solid $border-grey;
                }
                td {
                    font-size: 12px;
                    font-weight: 600;
                }
                td.item__name {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 21px;
                    .name__delivery {
                        font-weight: 400;
                        font-size: 10px;
                        padding-left: 23px;
                        @media(--onlyMobile) {
                            padding-left: 0;
                        }
                    }
                    .name__dimensions {
                        display: block;
                        color: $text-grey;
                        font-size: 11px;
                        i {
                            color: $primary;
                            font-size: 18px;
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }
                td.item__action {
                    position: relative;
                    @media(--onlyMobile) {
                        a {
                            position: absolute;
                            top: 10px;
                            right: 2px;
                        }
                    }
                    a {
                        linkColors: $black, red;
                    }
                }

                .item__description--mobile {
                    display: none;
                    @media(--onlyMobile) {
                        margin-top: 5px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                td.item__qyt, td.item__price, td.item__sum {
                    @media(--onlyMobile) {
                        display: none;
                    }
                }
            }

        }
    }
}

.checkout {
    .checkout__totals {
        margin-bottom: 50px;
        @media(--atDesktop) {
            margin-bottom: 0;
        }
        .totals__headline {
            display: none;
            @media(--atDesktop) {
                display: block;
            }
            background: $primary;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 17px 20px;
            color: #FFF;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;;
        }
        .totals__total {
            padding: 17px 0;
            @media(--atDesktop) {
                padding: 17px 20px;
                border: 1px solid $border-grey;
            }
            font-size: 12px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        .totals__sums {
            padding: 17px 0;
            font-size: 12px;
            border: none;
            @media(--atDesktop) {
                border: 1px solid $border-grey;
                padding: 17px 20px;
            }
            > div {
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
            }
            .sums__tax {
                color: $text-grey;
                padding-top: 20px;
                padding-bottom: 0;
            }
            .sums__shipping {
                border-bottom: 1px solid $border-grey;
            }
        }

    }
}

.checkout {
    .checkout__steps {
        @media(--atDesktop) {
            width: 75%;
        }
        .steps__title {
            margin-bottom: 18px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
        }
        .steps__contact {
            margin-bottom: 50px;
        }
        .steps__addresses {
            @media(--atDesktop) {
                justify-content: space-between;
                display: flex;
                > div {
                    width: 49%;
                }
            }
        }
        .steps__billing {
            margin-bottom: 50px;
        }
        .steps__shipping {
            margin-bottom: 50px;
            .steps__title {
                .item__input--checkbox {
                    margin-top: 25px;
                    @media(--atDesktop) {
                        margin-top: 0;
                    }
                }
                @media(--atDesktop) {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .shipping__wrapper {
                position: relative;
                top: -20px;
            }
        }
        .steps__hint {
            color: $text-grey;
            font-size: 12px;
            @media(--atDesktop) {
                padding-top: 15px;
            }
        }
    }
}

.checkout {
    .checkout__payment {
        @media(--atDesktop) {
            width: 75%;
        }
        margin-bottom: 70px;
        .payment__title {
            margin-bottom: 18px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
        }
        .payment__info {
            color: $text-grey;
            font-size: 12px;
            line-height: 22px;
            margin-bottom: 20px;
        }
        .payment__item {
            margin-bottom: 20px;
            font-size: 12px;
            font-weight: 500;
            position: relative;
            padding-left: 30px;
            a {
                font-size: 12px;
                font-weight: 500;
                linkColors: $black;
            }
            .item__info {
                color: $text-grey;
                font-weight: 400;
                @media(--onlyMobile) {
                    display: block;
                }
            }
            .item__price {
                margin: 0 6px;
                background: $primary;
                color: #FFF;
                padding: 3px 6px;
                border-radius: 15px;
                display: inline-block;
            }
            .item__radio {
                position: absolute;
                left: 0;
                top: 2px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #cdcfd0;
                display: inline-block;
                margin-right: 10px;
            }
            &.payment__item--active {
                .item__radio {
                    background: $primary;
                    border-color: #72c05a;
                    &:before {
                        position: absolute;
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        left: 3px;
                        top: 3px;
                        border-radius: 50%;
                        background: #FFF;
                        border: 1px solid #72c05a;
                    }
                }
            }
        }
    }
}


.checkout {
    &.checkout__preview {
        .checkout__cart {
            margin-bottom: 20px;
            thead {
                display: block;
            }
        }

        .preview__address {
            .address__headline {
                margin-bottom: 18px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
            }
            .address__items {
                @media(--atDesktop) {
                    justify-content: space-between;
                    display: flex;
                }
                margin-bottom: 50px;
                .row__title {
                    font-size: 11px;
                    text-transform: uppercase;
                    color: $text-grey;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .row__item {
                    font-size: 12px;
                    line-height: 22px;
                    margin-bottom: 10px;
                    &.row__item--address {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}


.checkout {
    &.checkout__preview {
        .checkout__totals {
            display: none;
            @media(--atDesktop) {
                display: block;
            }
        }

        .preview__actions {
            margin-top: 20px;
            @media(--atDesktop) {
                display: flex;
            }
            @media(--untilIpad) {
                .btn--border {
                    margin-left: 0;
                }
                .btn {
                    display: block;
                    margin-bottom: 20px;
                    width: 100%;
                    padding: 0px 25px;
                }
            }

        }

        .checkout__cart {
            .item__sum, .item__price {
                text-align: right;
            }
            thead {
                display: none!important;
            }

            @media(--atDesktop) {
                width: 75%;
            }
        }
        .preview__payment {
            @media(--atDesktop) {
                width: 75%;
            }
            padding: 30px 0;
            border-top: 1px solid $border-grey;
            .payment__headline {
                font-size: 11px;
                text-transform: uppercase;
                color: $text-grey;
                font-weight: 500;
                margin-bottom: 7px;
            }
            .payment__description {
                font-size: 12px;
                font-weight: 500;
            }
        }

        .preview__footer {
            justify-content: space-between;
            display: flex;
            @media(--desktopBreak) {
                flex-direction: column;
            }
            @media(--atDesktop) {
                width: 75%;
            }
        }
        .preview__comment {
            margin-bottom: 50px;
            width: 100%;
            @media(--atDesktop) {
                width: 60%;
            }
            textarea {
                width: 100%;
            }
            @media(--desktopBreak) {
                order: 2;
            }
        }
        .preview__terms {
            .row__label {
                display: inline-block;
                font-size: 12px;
                position: relative;
                top: 4px;
                a {
                    font-size: 12px;
                    linkColors: $black, $primary;
                    text-decoration: underline;
                }

            }
            .terms__row {

                .group__item {
                    display: inline-block;
                }
            }
        }
        .btn--border {
            margin-left: 20px;
        }
        .preview__totals {
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            div {
                margin-bottom: 10px;
            }
            .sums__total {
                font-weight: 600;
            }
            .sums__tax {
                color: $text-grey;
            }

            .totals__left {
                text-align: right;
                padding-right: 40px;
            }
            .totals__right {
                text-align: right;
            }

        }
    }
}


.checkout {
    &.checkout--success {
        .success__headline {
            margin-bottom: 18px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
        }
        .success__description {
            a {
                linkColors: $black, $primary;
                text-decoration: underline;
            }
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 21px;
                max-width: 600px;
            }
        }
    }
}