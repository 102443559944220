.plugin__headline {
    .headline__title {
        display: flex;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        text-transform: uppercase;
        color: $darkgrey;
        margin-bottom: 62px;
        &:before, &:after {
            content: '';
            flex: 1;
            border-bottom: 2px dotted $lightgrey;
            margin: auto 15px;
        }
        &:before {
            margin-left: 0;
        }
        &:after {
            margin-right: 0;
        }
    }
}