@font-face {
    font-family: "mlsystems-icons";
    src: url('./../fonts/mlsystems-icons.eot');
    src: url('./../fonts/mlsystems-icons.eot?#iefix') format('eot'),
        url('./../fonts/mlsystems-icons.woff') format('woff'),
        url('./../fonts/mlsystems-icons.ttf') format('truetype'),
        url('./../fonts/mlsystems-icons.svg#mlsystems-icons') format('svg');
}

.icon:before {
    font-family: "mlsystems-icons";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
}


.icon-arrow-big-left:before {
    content: "\E001";
}

.icon-arrow-big-right:before {
    content: "\E002";
}

.icon-cart:before {
    content: "\E003";
}

.icon-cross_16px_14px:before {
    content: "\E004";
}

.icon-dimensions:before {
    content: "\E005";
}

.icon-email:before {
    content: "\E006";
}

.icon-fax:before {
    content: "\E007";
}

.icon-mobile:before {
    content: "\E008";
}

.icon-phone:before {
    content: "\E009";
}

.icon-remove:before {
    content: "\E00A";
}