/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select: none;
    outline: none !important;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Make `a` like a button */
[role="button"] {
    color: inherit;
    cursor: default;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    user-select: none;
}

.btn {
    text-transform: uppercase;
    display: inline-block;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    line-height: 48px;
    padding: 0px 50px;
    background: $button-background;
    color: #FFF;
    border-radius: 50px;
    &:hover {
        text-decoration: none;
        cursor: pointer;
        background: $button-background-hover;
    }
}

.btn--black {
    background: $black;
    color: #FFF;
    &:hover {
        opacity: 0.9;
        text-decoration: none;
        cursor: pointer;
        background: $black;
    }
}

.btn--border {
    background: #FFF;
    border: 2px solid $primary;
    color: $primary;
    &:hover {
        text-decoration: none;
        cursor: pointer;
        background: $primary;
        color: #FFF;
    }
}