* { box-sizing: border-box; }

@custom-media --atWidescreen (min-width: 1024px);
@custom-media --atDesktop (min-width: 1024px);
@custom-media --ipad (min-width: 768px);
@custom-media --untilIpad (max-width: 768px);
@custom-media --upIpad (min-width: 768px);
@custom-media --onlyMobile (max-width: 480px);
@custom-media --upMobile (min-width: 480px);
@custom-media --desktopBreak (max-width: 650px);

.container--centered {
    max-width: 1360px;
    padding: 0 16px;
    margin: 0 auto;
    @media (--atWidescreen) {
        padding: 0 36px;
    }
}
