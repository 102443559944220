.plugin__teaser {
    display: flex;
    margin-bottom: 90px;
    @media (--untilIpad) {
        flex-wrap: wrap;
    }
    .teaser__item {
        width: 100%;
        position: relative;
        a {
            linkColors: $black, $black;
        }
        .teaser__image {
            /*transition: all .2s linear;*/
            float: left;
        }
        a:hover .teaser__image {
            /*transform: scale(1.05) rotate(0.02deg);*/
        }

        .teaser__description {
            text-align: center;
            display: inline;
            left: 0;
            position: absolute;
            font-size: 24px;
            background: rgba(255,255,255,0.8);
            top: 64px;
            padding: 15px 15px;
            z-index: 5000;
            @media (--untilIpad) {
                top: 0;
                font-size: 12px;
                width: 100%;
            }

        }
    }
    .teaser__item--left {
        position: relative;
        color: #FFF;
    }
    .teaser__item--right {
        position: relative;
        color: $black;
        color: #FFF;
    }
    .btn--black {
        margin-top: 50px;
    }

    .teaser__image {
        width: 100%;
        max-width: 100%;
    }
}
