.contact {
    margin-top: 50px;
    margin-bottom: 50px;
    .contact__header, .contact__success {
        font-style: 12px;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    &.contact--tent {

    }
}