.breadcrumb {
    margin-top: 40px;
    margin-bottom: 35px;

    .breadcrumb__item {
        font-size: 12px;
        linkColors: $primary, $primary;
        &:after {
            content: ">";
            display: inline-block;
            position: relative;
            color: $text-grey;
            margin-left: 5px;
        }

    }
    .breadcrumb__item--active {
        linkColors: $text-grey, $text-grey;
        cursor: text;
        &:hover {
            text-decoration: none;
        }
        &:after {
            content: "";
        }
    }

}