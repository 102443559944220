html {
    padding: 0;
    margin: 0;
}

b,
strong {
  font-weight: 500;
}

.page {
    min-height: 600px;
}


.page .page__content {
    margin-bottom: 120px;
    h1 {
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        text-transform: none;
        margin-bottom: 28px;
    }
    a {
        linkColors: $text-grey, $text-grey;
        text-decoration: underline;
    }

    h2 {
        margin-bottom: 38px;
        font-size: 14px;
        text-transform: uppercase;
    }

    strong {
        font-weight: 500;
    }
    p {
        font-weight: 400;
        line-height: 21px;
        font-size: 14px;
    }
    .content__image {
        img {
            max-width: 100%;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        li {
            padding-left: 22px;
            text-indent: -9px;
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 21px;
            font-size: 14px;
            &:before {
                content: "• ";
                color: $primary;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    ol {
        counter-reset: item;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        position: relative;
        li {
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 21px;
            font-size: 14px;
            padding-left: 25px;
            > ol {
                margin-top: 20px;
            }
            &:before {
                left: 0;
                position: absolute;
                content: counter(item) ". ";
                counter-increment: item;
                color: $primary;
                display: inline-block;
            }
        }
    }
}
