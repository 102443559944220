html {
    body {
        .page {
            position: relative;
            transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        &.offcanvas--active {
            height: 100vh;
            overflow: hidden;
            .page {
                z-index: 2;
                background-color: white;
            }
            .page{
                z-index: 1;
                transform: translate3d(-100vw, 0, 0);
            }
            .navigation--offcanvas {
                display: block;
                z-index: 0;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
    &.lt-ie10 {
        body.offcanvas--active {
            .page {
                display: none;
            }
        }
    }
}


#header {
    .hamburger {
        padding: 24px 20px;
        padding-top: 29px;
        padding-right: 15px;
        align-items: center;
        display: flex;
        @media (--atDesktop) {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
        .hamburger__description {
            @media (--onlyMobile) {
                display: none;
            }
            padding-left: 5px;
            font-weight: 500;
            font-size: 13px;
            position: relative;
            top: -1px;
            color: $primary;
        }

        .hamburger__content {
            width: 20px;
            height: 12px;
            display: block;
            position: relative;
            .content__line {
                position: absolute;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                transform: translateZ(0);
                background: $primary;
                transition: background-color .35s, transform .175s cubic-bezier(.215, .61, .355, 1), opacity .175s cubic-bezier(.215, .61, .355, 1);
                &.content__line_1 {
                    top: 0;
                }
                &.content__line_2 {
                    top: 5px;
                }
                &.content__line_3 {
                    top: 10px;
                }
            }
        }
    }

}

body.offcanvas--active {
    .hamburger {
        .hamburger__content {
            .content__line {
                &.content__line_1 {
                    transform: translateY(5px) rotate(45deg)!important;
                }
                &.content__line_2 {
                    opacity: 0;
                }
                &.content__line_3 {
                    transform: translateY(-5px) rotate(-45deg)!important;
                }

            }
        }
    }
}

.navigation--offcanvas {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    list-style: none;
    background: #f8f8f8;
    overflow: scroll;
    margin-top: 66px;
    padding-top: 20px;
    ul {
        margin: 0;
        padding: 0 0 0 0;
    }
    > ul > li {
        margin-bottom: 20px;
    }
    > ul > li > ul > li {
        margin-bottom: 10px;
    }
    > ul > li > a {
        text-transform: uppercase;
        font-weight: bold;
    }
    li {
        list-style: none;
        a {
            linkColors: $black, $primary;
            font-size: 13px;
            padding-left: 16px;
            text-decoration: none;
            display: block;
        }
    }
}
