body {
    font-family: "SinkinSans";
    font-size: 14px;
}

a {
    text-decoration: none;
    &:focus{
        outline: 0;
    }
}
a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}

h1,h2,h3,h4, p {
    margin-top: 0;
}
